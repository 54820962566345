<template lang="pug">
v-card
  v-card-title
    h2.card-title {{ 'base.filter' | translate }}
  v-card-content
    v-date-field(
      :label="$t('base.date_from')"
      :date-to="this.filterMaxDate"
      v-model="form.date_from")
    v-date-field(
      :label="$t('base.date_to')"
      :date-to="this.filterMaxDate"
      v-model="form.date_to")
    v-select.mb-3(
      :label="$t('base.status')"
      item-value="key"
      :options="options.statuses"
      v-model="form.status")
    v-multiselect.mb-3(
      item-name="name"
      item-value="id"
      :label="$t('base.housing')"
      :options="housingsList"
      v-model="form.housing_ids")
    v-multiselect.mb-3(
      item-name="name"
      item-value="id"
      :label="$t('base.ration')"
      :options="rationsList"
      @input="selectedRation"
      v-model="form.ration_ids")
    v-multiselect.mb-3(
      item-name="name"
      item-value="id"
      :label="$t('base.recipe')"
      :options="recipesForList"
      @input="selectedRecipe"
      v-model="form.recipe_ids")
    v-multiselect.mb-3(
      item-name="name"
      item-value="id"
      :label="$t('base.ingredient')"
      :options="ingredientsForList"
      v-model="form.ingredient_ids")
    v-checkbox(
      :label="$t('actions.allow_inactive_recipes')"
      v-model="form.with_disabled")
  v-card-actions
    v-btn.mr-2(
      :loading="loading"
      @click="onApply") {{ 'actions.apply' | translate }}
    v-btn(
      text
      @click="onReset") {{ 'actions.clear' | translate }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { PLANNING_STATUSES } from '@/util/consts.js'

export default {
  name: 'FilterReportsModal',

  async mounted () {
    const filters = this.$store.getters.reportsFilters
    for (const key in filters) {
      this.form[key] = filters[key]
    }
    await this.fetchRationsList()
    const params = []
    if (filters.ration_ids) {
      params['ration_ids'] = filters.ration_ids
    }
    if (filters.recipe_ids) {
      params['recipe_ids'] = filters.recipe_ids
    }
    if (params.length) {
      await this.fetchRecipesList(params)
      await this.fetchIngredients(params)
    }
    await this.fetchHousingsList()
  },

  data: () => ({
    form: {
      date_from: null,
      date_to: null,
      status: null,
      recipe_ids: [],
      ration_ids: [],
      ingredient_ids: [],
      with_disabled: false,
      housing_ids: []
    },
    options: {
      statuses: Object
        .entries(PLANNING_STATUSES)
        .map(item => ({
          key: item[0],
          ...item[1]
        }))
        .filter(item => item.key === 'done' || item.key === 'uncompleted')
    },
    loading: false,
    filterMaxDate: new Date(new Date().setDate(new Date().getDate()))
  }),

  computed: {
    ...mapGetters([
      'recipesList',
      'ingredients',
      'rationsList',
      'housingsList'
    ]),
    recipesForList () {
      return this.recipesList
        .filter(item => this.form.ration_ids.includes(item.ration_id))
    },
    ingredientsForList () {
      return this.ingredients
        .filter((item) => {
          return item.used_in_recipes.some(recipe => this.form.recipe_ids.includes(recipe))
        })
    }
  },

  methods: {
    ...mapActions([
      'applyReportsFilters',
      'fetchRecipesList',
      'fetchRationsList',
      'fetchIngredients',
      'fetchHousingsList'
    ]),

    async onApply () {
      this.loading = true
      const filters = {}
      for (const key in this.form) {
        if (this.form[key] !== null) {
          filters[key] = this.form[key]
        }
      }
      await this.applyReportsFilters(filters)
      this.loading = false
      this.$emit('close')
    },

    onReset () {
      for (const key in this.form) {
         if (key === 'ration_ids' ||
           key === 'recipe_ids' ||
           key === 'ingredient_ids' ||
           key === 'housing_ids') {
            this.form[key] = []
          } else {
            this.form[key] = null
          }
      }
    },

    selectedRation (value) {
      this.form.recipe_ids = []
      this.form.ingredient_ids = []
      const params = {
        ration_ids: this.form.ration_ids
      }
      this.fetchRecipesList(params)
      this.fetchIngredients(params)
    },

    selectedRecipe (value) {
      this.form.ingredient_ids = []
      const params = {
        ration_ids: this.form.ration_ids,
        recipe_ids: this.form.recipe_ids
      }
      this.fetchIngredients(params)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
